<template>
    <div class="height1">
        <bread></bread>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <div class="table-top">
                    <div class="iq-search-bar">
                        <el-form :model="searchForm" :inline="true">
                            <el-form-item>
                                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                                    :picker-options="pickerOptions0" size="small" style="margin-right: 10px" @change="search">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item>
                                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="姓名/账号"
                                    @keyup.enter.native="SearchInputKeydown" clearable ></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" size="small" @click="search()">搜索</el-button>
                            </el-form-item>
                            <el-form-item>
                                <download-excel v-if="tableData.length > 0" :data="tableData" :fields="json_fields"
                                    worksheet="My Worksheet" :header="title" :name="`${title}.xls`" class="excel">
                                    <el-button type="warning" size="mini" class="el-icon-download">导出当前数据</el-button>
                                </download-excel>
                            </el-form-item>
                            <el-form-item>
                                <download-excel v-if="DataCount" :fields="json_fields" :fetch="fetchData"
                                    worksheet="My Worksheet" :header="title" :name="`${title}_All.xls`" class="excel">
                                    <el-button type="danger" size="mini" class="el-icon-download">导出全部数据</el-button>
                                </download-excel>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <el-table :data="tableData" stripe height="calc(100% - 90px)" style="width: 100%" :header-cell-style="{background:'#F5F7FC'}">
                    <el-table-column prop="userName" label="姓名">
                    </el-table-column>
                    <el-table-column prop="phone" label="账号" align="center">
                    </el-table-column>
                    <el-table-column prop="money" label="累计获利金额（元）" align="center">
                    </el-table-column>
                    <el-table-column prop="createTime" label="时间" align="center">
                        <template slot-scope="scope"> {{ scope.row.lastTime | timefilters }} </template>
                    </el-table-column>
                    <el-table-column label="操作" width="120" align="center">
                        <template slot-scope="scope">
                            <span class="primary optionBtn" @click="toDetail(scope.row)">详情</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex"
                    :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import bread from "../../../components/common/breadcrumb"
import { Distributor } from "../../../components/HospitalDomain/Distributor";
export default {
    name: "Profit",
    components: {
        bread
    },
    data() {
        var distributor = new Distributor(this.TokenClient, this.Services.Authorization);
        return {
            DistributorDomain: distributor,
            tableData: [],
            searchForm: {
                statrTime: '',
                endTime: '',
                keyWord: '',
                datepicker: ''
            },
            pickerOptions0: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
            PageIndex: 1,
            PageTotal: 1,
            PageSize: 15,
            DataTotal: 0,
            title: '分销获利统计',
            DataCount: 0,
            json_fields: {
                "姓名": {
                    callback: (val) => {
                        return `${val.userName}`
                    }
                },
                "账号": {
                    callback: (val) => {
                        return `${val.phone}`
                    }
                },
                "累计获利金额": 'money',
                "时间": {
                    filed: 'lastTime',
                    callback: (val) => {
                        var val = val.lastTime
                        return this.$fliters.timefilters(val)
                    }
                },
            },
        }
    },
    mounted() {
        this.getList();
    },
    methods: {
        toDetail(item) {
            this.$router.push({
                path: '/STATS/ProfitDetail',
                query: {
                    userId:item.userId
                }
            })
        },
        ChangePage(pageIndex) {
            this.PageIndex = pageIndex;
            this.getList();
        },
        search() {
            this.PageIndex = 1;
            this.getList()
        },
        async fetchData() {
            var _this = this;
            return await _this.getAllData()
        },
        getAllData() {
            var _this = this;
            return new Promise((resolve, reject) => {
                var item = _this.searchForm;
                if (_this.searchForm.datepicker) {
                    item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00';
                    item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59';
                } else {
                    item.startTime = "";
                    item.endTime = "";
                }
                _this.DistributorDomain.OrgUserProfit(item.startTime, item.endTime, 1, item.keyWord, _this.DataCount,
                    function (data) {
                        resolve(data.data.results);
                    },
                    function (err) {
                        resolve('');
                        console.log(err);
                    });
            })

        },
        getList() {
            var _this = this;
            var item = _this.searchForm;
            if (_this.searchForm.datepicker) {
                item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00';
                item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59';
            } else {
                item.startTime = "";
                item.endTime = "";
            }
            _this.DistributorDomain.OrgUserProfit(item.startTime, item.endTime, _this.PageIndex, item.keyWord, null,
                function (data) {
                    _this.tableData = data.data.results;
                    _this.PageIndex = data.data.pageIndex;
                    _this.PageSize = data.data.pageSize;
                    _this.PageTotal = data.data.pageCount;
                    _this.DataTotal = data.data.dataTotal;
                    if (!item.keyWord) {
                        _this.DataCount = data.data.dataTotal;
                    }
                },
                function (error) {
                    console.log(error);
                });
        },
    }
}
</script>

<style scoped>

</style>
